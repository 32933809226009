<template>
    <div class="ticketbody">
        <a-button type="primary" @click="OpenTicketCreate">新建工单</a-button>
        <a-spin :spinning="loading">
            <a-table :columns="columns" :data-source="list" :pagination="false">
                <template slot="date" slot-scope="date">
                        {{date|GetDate}}
                </template>
                <template slot="action" slot-scope="action">
                    <a-button type="link" @click="OpenTicket(action.id)">查看</a-button>
                </template>
            </a-table>
        </a-spin>
        <div class="paginationbox">
            <a-pagination v-model:current="current" :total="totalpage" :defaultPageSize="20" />
        </div>




        <a-modal v-model:visible="ticketCreateModal" title="新建工单" width="80%">
			<a-form :layout="'horizontal'" :model="ticketForm">
				<a-form-item label="标题">
					<a-input v-model:value="ticketForm.title" />
				</a-form-item>
                <a-form-item label="内容">
					<a-textarea v-model:value="ticketForm.content" />
				</a-form-item>
                <!-- <a-form-item label="附件">
                    <a-upload  v-model:file-list="ticketForm.fileList" :action="'https://api.wormholecloud.com/api/v1/ticketuploadfile'">
                        <a-button>
                        
                            上传
                        </a-button>
                    </a-upload>
                </a-form-item> -->
			</a-form>
			<template #footer>
				<a-button key="back" @click="ticketCreateModal=false">关闭</a-button>
				<a-button key="submit" type="primary" :loading="handleTicketloading" @click="handleTicketCreate">确定</a-button>
			</template>
    	</a-modal>
        <a-modal v-model:visible="OpenTicketModal" title="查看工单" width="80%">
			<div class="messagelist">
                <div  v-for="(item,i) in ticketMessageList" :class="item.message_type==0?'messageRight':'messageLeft'">
                    {{item.message}}
                </div>
                
            </div>
            <div>
                <a-textarea v-model="ticketMessage"></a-textarea>
                    
            </div>
			<template #footer>
                <a-button key="submit" type="primary"  @click="ReplyMessage">回复</a-button>
				<a-button key="back" @click="OpenTicketModal=false">关闭</a-button>
			</template>
    	</a-modal>
    </div>
</template>
<script>
import { message } from 'ant-design-vue'
import {TicketGet,TicketCreate,TicketMessageList,ReplyMessageTicket} from '../api/request';
import {formatDate} from '../plugins/formatDate'
export default ({
    components: {
       
    },
    filters: {
        GetDate(value){
            var date = new Date(value * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
        }
    },
    data() {
		return {
            currentTicket:"",
            ticketMessageList:[],
            ticketMessage:"",
            OpenTicketModal:false,
            handleTicketloading:false,
            ticketForm:{
                title:"",
                content:"",
                // fileList:[],
            },
            ticketCreateModal:false,
            loading:false,
            current:1,
            totalpage:1,
            columns:[
                {
                    title: '工单号',
                    dataIndex: 'id',
                },
                {
                    title: '标题',
                    dataIndex: 'title',
                },
                {
                    title: '日期',
                    dataIndex: 'date',
                    scopedSlots: { customRender: 'date' },
                },
                {
                    title: '操作',
                    scopedSlots: { customRender: 'action' },
                },
            ],
            list:[
                
            ]
        }
    },
    watch:{
        current(oldval,newval){
            this.GetData()
        }
    },
    mounted(){
        this.GetData()
    },
    methods:{
        ReplyMessage(){
            ReplyMessageTicket({
                ticket_id:this.currentTicket,
                message:this.ticketMessage
            }).then(res=>{
                this.ticketMessage=""
                this.GetTicketList(this.currentTicket)
            })
        },
        customRequest(req){
            console.log(req)
        },
        beforeUpload(files){
            console.log(files)
        },
        OpenTicketCreate(){
            this.ticketForm={
                title:"",
                content:"",
                fileList:[],
            }
            this.ticketCreateModal=true
        },
        handleTicketCreate(){
            this.handleTicketloading=true
            TicketCreate(this.ticketForm).then(res=>{
                this.handleTicketloading=false
                
                if(res.status==1){
                    this.ticketCreateModal=false
                    this.GetData()
                    message.success("创建成功")
                }else{
                    message.error(res.message)
                }
            }).catch(err=>{
                this.handleTicketloading=false
            })
        },
        OpenTicket(ticketID){
            this.currentTicket=ticketID
            this.OpenTicketModal=true
            this.ticketMessage=""
            this.ticketMessageList=[]
            this.GetTicketList(ticketID)
            

        },
        GetTicketList(ticketID){
            TicketMessageList(ticketID).then(res=>{
                this.ticketMessageList=res.data
            })
        },
        GetData(){
            this.loading=true
            TicketGet(this.current).then(res=>{
                this.list=res.data.list
                this.totalpage=res.data.total
                this.loading=false
            })
        }
    }
})
</script>
<style lang="scss">
.floatbuttonenter{
    position: absolute;
    z-index: 1111;
}
.messageRight{
    width: 100%;
    display: flex;
    flex-flow: row-reverse;
}
.messageLeft{
    width: 100%;
    display: flex;
    flex-flow: row;
}
.messagelist{
    width: 100%;
    height: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
}
.paginationbox{
    margin-top: 10px;
    display: flex;
    flex-flow: row-reverse;
}
.ticketbody{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>