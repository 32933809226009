<template>
    <div class="indexbody">
        <img src="/images/index/background.gif" style="width:100%;height:100%;object-fit: cover;z-index: 1;position: absolute;" />
        <div class="indexBox">
            <div class="indexHead">
                <div class="indexHeadarea">
                    <img src="/images/index/logo-left-white.png" style="width: 240px;margin: 30px;" />
                </div>
               
            </div>
            <div class="indexContent">
                    <div class="title">WORMHOLE CLOUD</div>
                    <div class="desc">开启“数据包”的奇幻漂流之旅</div>
                    <div class="userbtn">
                        <a-button type="primary" style="margin-right: 10px;" @click="$router.push('/sign-up')">注册</a-button>
                        <a-button type="primary" style="margin-left: 10px;" @click="$router.push('/sign-in')">登录</a-button>
                    </div>
               </div>
        </div>
    </div>
</template>
<script>
export default ({
    components: {
       
    },
    data() {
		return {}
    }
})
</script>
<style lang="scss">
.desc{
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
}
.title{
    font-size: 32px;
    font-weight: 600;
}
.userbtn{
    margin-top: 20px;
    display: flex;
    flex-flow: row;
}
.indexContent{
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    width: 100%;
    height: 100%;
}
.indexHeadarea{
    width: 100%;
}
.indexHead{
    width: 100%;
    display: flex;
    flex-flow: row;
    position: absolute;
    top: 0;
    z-index: 3;
}
.indexBox{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.indexbody{
    color: white !important;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>