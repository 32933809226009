<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
	<div>
		<a-row type="flex" :gutter="24">
			<a-col :span="24" :md="24">
				<a-carousel>
					<div class="gonggaoblock" v-for="item in 1" :key="item">
							<img class="gonggao-bg" src="/images/gonggaobg.jpg" />
							<h3 class="gonggao-title">暂无公告</h3>
						</div>
				</a-carousel>
				
			</a-col>
			
		</a-row>
		<a-row type="flex" :gutter="24">
			<a-col :span="24" :md="8">
				<a-card :bordered="false" class="usertrafficInfo">
					<template #title>
			<h6>流量信息</h6>			
		</template>
		<template #extra>
			<a-button type="primary" @click="buyTrafficModal=true">
							购买流量
						</a-button>	
		</template>
					<div class="usertraffic">
						<div class="trafficjess">
							<el-progress type="dashboard" :percentage="trafficInfo.total_bandwidth==0?0:Math.ceil(((trafficInfo.total_bandwidth-trafficInfo.usage_bandwidth)/trafficInfo.total_bandwidth).toFixed(2)*100)" :color="colors"></el-progress>
						</div>
						<div class="trafficText">
							<div class="trafficDesc">
								<div>总流量</div>
								<div class="trafficTextStyle">{{(trafficInfo.total_bandwidth/1024/1024/1024).toFixed(2)}}GB</div>
							</div>
							<div class="trafficDesc">
								<div>已用流量</div>
								<div class="trafficTextStyle">{{(trafficInfo.usage_bandwidth/1024/1024/1024).toFixed(2)}}GB</div>
							</div>
							<div class="trafficDesc">
								<div>剩余流量</div>
								<div class="trafficTextStyle">{{((trafficInfo.total_bandwidth-trafficInfo.usage_bandwidth)/1024/1024/1024).toFixed(2)}}GB</div>
							</div>
						</div>
						<div class="trafficButton">
						
						<a-button type="primary" @click="OpenClash">
							一键导入配置
						</a-button>
						<a-button type="primary" id="equsn" @click="copy">
							复制订阅链接
						</a-button>
						<a-button type="primary" @click="$router.push('/trafficrecoder')">
							查看流量记录
						</a-button>
					</div>
					</div>
					
				</a-card>
				
			</a-col>
			<a-col :span="24" :md="8">
					<a-card :bordered="false" class="usertrafficInfo">
						<template #title>
							<h6>客户端下载</h6>
						</template>
						<div class="usertraffic">
							<div class="downloadlist">
								<div class="splitDiv">
									<div><img src="/images/system/windows.svg" width="20"><span class="SystemTitle">Windows客户端</span></div>
									<ul style="padding-top:5px">
										<li><a href="https://clashclient.obs.cn-north-4.myhuaweicloud.com/clash-windows-amd64.exe" >Clash amd64客户端</a></li>
										<li><a href="https://clashclient.obs.cn-north-4.myhuaweicloud.com/clash-windows-arm64.exe" >Clash arm64客户端</a></li>
									</ul>
									<div><img src="/images/system/linux.svg" width="20"><span class="SystemTitle">Linux客户端</span></div>
									<ul style="padding-top:5px">
										<li><a href="https://clashclient.obs.cn-north-4.myhuaweicloud.com/clash-linux-amd64.tar.gz" >Clash amd64客户端</a></li>
									</ul>
									<div><img src="/images/system/macos.svg" width="20"><span class="SystemTitle">Macos客户端</span></div>
									<ul style="padding-top:5px">
										<li><a href="https://clashclient.obs.cn-north-4.myhuaweicloud.com/clash-macos.dmg">Clash 客户端</a></li>
									</ul>
								</div>
								<div class="splitDiv">
									<div><img src="/images/system/openwrt.svg" width="20"><span class="SystemTitle">Openwrt客户端</span></div>
									<ul style="padding-top:5px">
										<li><a href="https://clashclient.obs.cn-north-4.myhuaweicloud.com/clash-openwrt.ipk">OpenClash 客户端</a></li>
									</ul>
									<div><img src="/images/system/android.svg" width="20"><span class="SystemTitle">Android客户端</span></div>
									<ul style="padding-top:5px">
										<li><a href="https://clashclient.obs.cn-north-4.myhuaweicloud.com/clash-android.apk" >Clash 客户端</a></li>
									</ul>
									<div><img src="/images/system/ios.svg" width="20"><span class="SystemTitle">iOS客户端</span></div>
									<ul style="padding-top:5px">
										<li><a href="https://apps.apple.com/tw/app/shadowrocket/id932747118">Shadowrocket</a></li>
									</ul>
								</div>
							</div>
						</div>
					</a-card>
				</a-col>
				<a-col :span="24" :md="8">
					<a-card :bordered="false" class="usertrafficInfo">
						<template #title>
							<h6>推广信息</h6>
						</template>
						<template #extra>
							<a-button type="primary" @click="affTakingBalanceModal=true">
										佣金提现
									</a-button>
		</template>
						<div class="usertraffic">
							<div class="affbox">
								<div class="affdesc">
									<div class="afftitle">推广链接:</div>
									<div class="affvalue afflink">https://www.wormholecloud.com/#/sign-up?code={{$store.getters.getInviteCode}}</div>
								</div>
								<div class="affdesc">
									<div class="afftitle">返利比例:</div>
									<div class="affvalue">30%</div>
								</div>
								<div class="affdesc">
									<div class="afftitle">推广人数:</div>
									<div class="affvalue">{{affinfo.affcount}}</div>
								</div>
								<div class="affdesc">
									<div class="afftitle">佣金余额:</div>
									<div class="affvalue">CNY {{affinfo.affbalance.toFixed(2)}}</div>
								</div>
								<div class="affbutton">
									
									<a-button type="primary" @click="takingModal=true">
										提现记录
									</a-button>
									<a-button type="primary" @click="affBalanceRecordListModal=true">
										佣金记录
									</a-button>
									<a-button type="primary" @click="affUserListModal=true">
										推广记录
									</a-button>
								</div>
							</div>
						</div>
					</a-card>
				</a-col>
		</a-row>
		<a-row :gutter="24">
			<a-col :span="24" :md="24">
				<a-card :bordered="false" class="usertrafficInfo">
					<template #title>
			<h6>流量消耗趋势 单位MByte</h6>			
			<p>2023年</p>	
		</template>
		<template #extra>
			<div style="display: flex;flex-flow: column;">
				<a-badge color="primary" class="badge-dot-primary" text="上传流量" />
			<a-badge color="primary" class="badge-dot-secondary" text="下载流量" />
			</div>	
		</template>
						<chart-line v-if="showChart" :height="250" :data="lineChartData"></chart-line>
				</a-card>
			</a-col>
		</a-row>









		<a-modal v-model:visible="takingModal" title="提现记录" width="80%">
			<a-table :columns="takinglistcolumns" :data-source="takinglist" :pagination="false">
				<template slot="date" slot-scope="date">
					{{date|GetDate}}
				</template>
				<template slot="amount" slot-scope="amount">
					{{(amount/100).toFixed(2)}}
				</template>
				<template slot="payment" slot-scope="payment">
					{{payment==0?'支付宝':payment==1?'微信':payment==2?'USDT-TRC20':''}}
				</template>
				<template slot="status" slot-scope="status">
					{{status==0?'正在提现':status==1?'提现成功':status==2?'提现失败':''}}
				</template>
        	</a-table>
			<template #footer>
				<a-button key="back" @click="takingModal=false">关闭</a-button>
			</template>
    	</a-modal>

		<a-modal v-model:visible="affBalanceRecordListModal" title="佣金记录" width="80%">
			<a-table :columns="affbalancerecordlistcolumns" :data-source="affbalancelist" :pagination="false">
				<template slot="date" slot-scope="date">
					{{date|GetDate}}
				</template>
				<template slot="consume_amount" slot-scope="consume_amount">
					{{(consume_amount/100).toFixed(2)}}
				</template>
				<template slot="amount" slot-scope="amount">
					{{(amount/100).toFixed(2)}}
				</template>
        	</a-table>
			<template #footer>
				<a-button key="back" @click="affBalanceRecordListModal=false">关闭</a-button>
			</template>
    	</a-modal>

		<a-modal v-model:visible="affUserListModal" title="推广记录" width="80%">
			<a-table :columns="affuserlistcolumns" :data-source="affuserlist" :pagination="false">
				<template slot="date" slot-scope="date">
					{{date|GetDate}}
				</template>
        	</a-table>
			<template #footer>
				<a-button key="back" @click="affUserListModal=false">关闭</a-button>
			</template>
    	</a-modal>


		<a-modal v-model:visible="affTakingBalanceModal" title="佣金提现" width="80%">
			<a-form :layout="'horizontal'" :model="takingFrom">
				<a-form-item label="提现方式">
					<a-select
						ref="select"
						v-model:value="takingFrom.payment"
						>
						<a-select-option :value="0">支付宝</a-select-option>
						<a-select-option :value="1">微信</a-select-option>
					</a-select>
				</a-form-item>
				<a-form-item label="提现账号">
					<a-input v-model:value="takingFrom.payment_account" />
				</a-form-item>
			</a-form>
			<template #footer>
				<a-button key="back" @click="affTakingBalanceModal=false">关闭</a-button>
				<a-button key="submit" type="primary" :loading="handleTakingloading" @click="handleTaking">确定</a-button>
			</template>
    	</a-modal>



		<a-modal v-model:visible="buyTrafficModal" title="流量购买" width="80%">
			<div class="buyform">
				<div class="amounttextss">{{buytrafficamount}}GB</div>
				<div class="amounttextss">CNY {{buytrafficamount|GetPrice}}元</div>
				<div style="width: 50%;">
					<a-row>
						<a-col :span="20">
							<a-slider v-model:value="buytrafficamount" :min="1" :max="10240" />
						</a-col>
						<a-col :span="4">
							<a-input-number v-model:value="buytrafficamount" :min="1" :max="10240" style="margin-left: 16px" />
						</a-col>
					</a-row>
				</div>
				<div class="qrcode" ref="qrCodeUrl" v-if="buyTrafficModal"></div>
				<div>请使用支付宝扫码支付</div>
			</div>
			<template #footer>
				<a-button key="back" @click="buyTrafficModal=false">关闭</a-button>
				<a-button key="submit" type="primary" :loading="handlebuyTrafficloading" @click="handlebuyTraffic">确定</a-button>
			</template>
    	</a-modal>
	</div>
</template>

<script>
import Clipboard from 'clipboard';
import QRCode from 'qrcodejs2'
import { message } from 'ant-design-vue'
	import {formatDate} from '../plugins/formatDate'
	import ChartLine from '../components/Charts/ChartLine.vue' ;
	import {AffTakingBalance,GetTakingList,GetUserInfo,GetAffCount,UserStatisticsTraffic,GetBalanceRecordList,AffUserList,ReqPay,GetOrderStatus} from '../api/request';
	export default ({
		components: {
			ChartLine,
		},
		filters: {
			GetDate(value){
				var date = new Date(value * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
				return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
			},
			GetPrice(value){
				if(value<=1){
					return value
				}

				if(value>1&&value<10){
					return (value*0.30).toFixed(2)
				}

				if(value>=10&&value<100){
					return (value*0.25).toFixed(2)
				}
				if(value>=100&&value<1000){
					return (value*0.1).toFixed(2)
				}

				if(value>=1000){
					return (value*0.08).toFixed(2)
				}
			}
		},
		data() {
			return {
				subcribeUrl:'https://api.wormholecloud.com/api/v1/subcribe/',
				qrcode:null,
				getOrderStatusInv:null,
				buytrafficamount:1,
				handlebuyTrafficloading:false,
				buyTrafficModal:false,
				handleTakingloading:false,
				affTakingBalanceModal:false,
				takingModal:false,
				affBalanceRecordListModal:false,
				affUserListModal:false,
				showChart:false,
				takinglist:[],
				affbalancelist:[],
				affuserlist:[],
				takingFrom:{
					payment:0,
					payment_account:""
				},
				takinglistcolumns:[
                {
                    title: '日期',
                    dataIndex: 'date',
                    scopedSlots: { customRender: 'date' },
                },
                {
                    title: '金额',
                    dataIndex: 'amount',
                    scopedSlots: { customRender: 'amount' },
                },
                {
                    title: '提现方式',
                    dataIndex: 'payment',
                    scopedSlots: { customRender: 'payment' },
                },
				{
                    title: '提现账号',
                    dataIndex: 'payment_account',
                },
				{
                    title: '状态',
                    dataIndex: 'status',
					scopedSlots: { customRender: 'status' },
                },
            ],
			affbalancerecordlistcolumns:[
                {
                    title: '日期',
                    dataIndex: 'date',
                    scopedSlots: { customRender: 'date' },
                },
				{
                    title: '用户',
                    dataIndex: 'from_user',
                },
                {
                    title: '消费金额',
                    dataIndex: 'consume_amount',
                    scopedSlots: { customRender: 'consume_amount' },
                },
				{
                    title: '返利金额',
                    dataIndex: 'amount',
                    scopedSlots: { customRender: 'amount' },
                },
            ],
			affuserlistcolumns:[

				{
                    title: '用户名',
                    dataIndex: 'username',
                },
				{
                    title: '注册日期',
                    dataIndex: 'date',
                    scopedSlots: { customRender: 'date' },
                },
            ],
				colors: [
				{color: '#f56c6c', percentage: 20},
				{color: '#e6a23c', percentage: 40},
				{color: '#5cb87a', percentage: 60},
				{color: '#1989fa', percentage: 80},
				{color: '#6f7ad3', percentage: 100}
				],
				lineChartData: {
					labels: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月","10月","11月", "12月"],
					datasets: [{
						label: "Upload Traffic",
						tension: 0.4,
						borderWidth: 0,
						pointRadius: 0,
						borderColor: "#1890FF",
						borderWidth: 3,
						data: [50, 40, 300, 220, 500, 250, 400, 230, 500,0,0,122],
						maxBarThickness: 6

					},
					{
						label: "Download Traffic",
						tension: 0.4,
						borderWidth: 0,
						pointRadius: 0,
						borderColor: "#B37FEB",
						borderWidth: 3,
						data: [30, 90, 40, 140, 290, 290, 340, 230, 400,0,0,122],
						maxBarThickness: 6

					}],
				},
				userid:'',
				trafficInfo:{
					total_bandwidth:0,
					usage_bandwidth:0,
				},
				affinfo:{
					affcount:0,
					affbalance:0.00,
				}
			}
		},
		watch:{
			takingModal(oval,nval){
				this.TakingListGet()
			},
			affBalanceRecordListModal(oval,nval){
				this.BalanceRecordGet()
			},
			affUserListModal(oval,nval){
				this.AffUserListGet()
			}
		},
		mounted(){

			this.GetUser()
			//获取推广人数
			GetAffCount().then(res=>{
				this.affinfo.affcount=res.data
			}).catch(err=>{})
			//获取流量趋势数据
			UserStatisticsTraffic().then(res=>{
				this.lineChartData.datasets[0].data=res.data.upload
				this.lineChartData.datasets[1].data=res.data.download
				this.showChart=true
			})

		},
		methods:{
			copy() {
                var clipboard = new Clipboard('#equsn',{
					text: () => {
						return this.subcribeUrl+"url?userid="+this.userid
					}
				})
                clipboard.on('success', e => {
                    message.success('复制成功');
                    // 释放内存
                    clipboard.destroy()
                })

                clipboard.on('error', e => {
                    // 不支持复制
                    message.error('该浏览器不支持自动复制');
                    // 释放内存
                    clipboard.destroy()
                })
            },
			OpenClash(){
				window.open(this.subcribeUrl+"onekey?userid="+this.userid)
			},
			GetUser(){
				GetUserInfo().then(res=>{
					this.userid=res.data.id
				this.trafficInfo.total_bandwidth=res.data.total_bandwidth
				this.trafficInfo.usage_bandwidth=res.data.usage_bandwidth
				this.affinfo.affbalance=res.data.aff_balance/100
				
				//console.log()
			}).catch(err=>{})
			},
			handlebuyTraffic(){
				if(this.qrcode!=null){
					this.qrcode.clear()
					this.qrcode=null
				}
				if(this.getOrderStatusInv!=null){
					clearInterval(this.getOrderStatusInv)
				}
				this.handlebuyTrafficloading=true
				ReqPay(this.buytrafficamount).then(res=>{
					this.handlebuyTrafficloading=false
					if(res.status!=1){
						message.error("请求失败")
					}else{
						var that=this
						this.getOrderStatusInv=setInterval(function(){
							GetOrderStatus(res.data.orderid).then(res=>{
								if(res.data!=0){
									if(res.data==1){
										message.success("支付成功")
										that.buyTrafficModal=false
									}else{
										message.error("支付失败")
									}
									clearInterval(that.getOrderStatusInv)
									that.getOrderStatusInv=null
									that.GetUser()
								}
							})
						},1000)
						//生成QR
						this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
								text: res.data.qrcode, // 需要转换为二维码的内容
								width: 100,
								height: 100,
								colorDark: '#000000',
								colorLight: '#ffffff',
								correctLevel: QRCode.CorrectLevel.H
							})
							console.log(this.qrcode)
						
					}
				})
			},
			handleTaking(){
				this.handleTakingloading=true
				AffTakingBalance({payment:this.takingFrom.payment,payment_account:this.takingFrom.payment_account}).then(res=>{
					this.handleTakingloading=false
					if(res.status!=1){
						message.error(res.message);
					}else{
						message.success("提交成功")
					}
					
				})
			},
			TakingListGet(){
				GetTakingList().then(res=>{
					this.takinglist=res.data
				})
			},
			BalanceRecordGet(){
				GetBalanceRecordList().then(res=>{
					this.affbalancelist=res.data
				})
			},
			AffUserListGet(){
				AffUserList().then(res=>{
					this.affuserlist=res.data
				})
			}
		}
	})

</script>

<style lang="scss">
.amounttextss{
	font-size: 36px;
	font-weight: 600;
}
.buyform{
	width: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}
.affbutton{
	width: 100%;
	margin-top: 50px;
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-around;
}
.afflink{
	color: #1890FF;
	cursor: pointer;
}
.affvalue{
	margin-left: auto;
}
.afftitle{
	display: flex;
	font-weight: 600;
}
.affdesc{
	width: 100%;
	display: flex;
	flex-flow: row;
	margin-top: 10px;
	justify-content: flex-end;
}
.affbox{
	width: 100%;
	height: 100%;
	padding: 10px;
	display: flex;
	flex-flow: column;
}
.SystemTitle{
	padding-left: 5px;
	font-weight: 600;
}
.splitDiv{
	width: 50%;
	height: 100%;
	display: flex;
	flex-flow: column;
}
.downloadlist{
	width: 100%;
	height: 100%;
	padding:10px;
	display: flex;
	flex-flow: row;
}
.trafficChart{
	width: 100%;
}
.trafficGraph{
	width: 100%;
	height: 330px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}
.trafficButton{
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	height: 50px;
}
.trafficTextStyle{
	font-size: 22px;
}
.trafficDesc{
	width: 33.3333333%;
	height: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}
.trafficText{
	width: 100%;
	height: 100px;
	display: flex;
	flex-flow:row;
	align-items: center;
	justify-content: center;
}
.trafficjess{
	width: 100%;
	height: 100px;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}
.usertraffic{
	height: 250px;
	widows: 100%;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}
.usertrafficInfo{
	border: none;
	box-shadow: none;
	border: 1px solid #f5f5f5;
	background-color: #fafafa;
	margin-top: 10px;
	
}
.gonggao-title{
	position: absolute;
	z-index: 2;
	color: white;
	bottom: 40px;
	left: 40px;
}
.gonggao-bg{
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
}
.gonggaoblock{
	width: 100%;
	height: 350px;
	cursor: pointer;
}
</style>