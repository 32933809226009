import axios from 'axios'
import { message } from 'ant-design-vue'
import store from '../store/index'
const ConfigBaseURL = 'https://api.wormholecloud.com/' //默认路径，这里也可以使用env来判断环境

//使用create方法创建axios实例
export const ServicePost = axios.create({
  timeout: 7000, // 请求超时时间
  baseURL: ConfigBaseURL,
  method: 'Post',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
})
// 添加请求拦截器
ServicePost.interceptors.request.use(config => {
  config.headers.Authorization=store.state.userInfo.token
  return config
})
// 添加响应拦截器
ServicePost.interceptors.response.use(response => {
  // console.log(response)
  return response.data
}, error => {
  console.log('TCL: error', error)
  const msg = error.Message !== undefined ? error.Message : ''
  message.error('网络错误' + msg);
  return Promise.reject(error)
})