import {ServiceGet} from './ServiceGet'
import {ServicePost} from './ServicePost'
export function GetUserInfo() {
  return ServiceGet({
    url: '/api/v1/user/userinfo',
  })
}
export function GetAffCount() {
    return ServiceGet({
      url: '/api/v1/user/useraffcount',
    })
  }
  export function UserStatisticsTraffic() {
    return ServiceGet({
      url: '/api/v1/user/userstatisticstraffic',
    })
  }
  export function GetNodeList() {
    return ServiceGet({
      url: '/api/v1/user/nodelist',
    })
  }
  export function GetTrafficRecords(pagenum) {
    return ServiceGet({
      url: '/api/v1/user/trafficrecords?pagenum='+pagenum,
    })
  }
export function GetTakingList() {
    return ServiceGet({
        url: '/api/v1/user/takinglist',
    })
}
export function GetBalanceRecordList() {
    return ServiceGet({
        url: '/api/v1/user/balancerecordlist',
    })
}
export function AffUserList() {
    return ServiceGet({
        url: '/api/v1/user/affuserlist',
    })
}
export function AffTakingBalance(data) {
    return ServicePost({
        url: '/api/v1/user/afftakingbalance',
        data
    })
}




export function TicketGet(pagenum) {
    return ServiceGet({
        url: '/api/v1/user/ticketlist?pagenum='+pagenum,
    })
}
export function TicketCreate(data) {
    return ServicePost({
        url: '/api/v1/user/createticket',
        data
    })
}
export function TicketUpdate(data) {
    return ServicePost({
        url: '/api/v1/user/updateticket',
        data
    })
}
export function TicketMessageList(ticket) {
    return ServiceGet({
        url: '/api/v1/user/ticketmessage?ticket='+ticket,
    })
}
export function ReplyMessageTicket(data) {
    return ServicePost({
        url: '/api/v1/user/replymessage',
        data
    })
}
export function ReqPay(amount) {
    return ServiceGet({
        url: '/api/v1/user/order?amount='+amount,
    })
}
export function GetOrderStatus(orderid) {
    return ServiceGet({
        url: '/api/v1/user/orderstatus?orderid='+orderid,
    })
}
