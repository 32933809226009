<template>
    <div class="mybody">

    </div>
</template>
<script>
export default ({
    components: {
       
    },
    data() {
		return {}
    }
})
</script>
<style lang="scss">
.mybody{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>