<template>
    <div class="wikibody">
        <div class="wikilist">
            <a-card>
                <div class="cardbox" @click="visible=true">Windows使用教程</div>
            </a-card>
            <a-card style="margin-top: 20px;">
                <div class="cardbox">Macos使用教程</div>
            </a-card>
            <a-card style="margin-top: 20px;">
                <div class="cardbox">Android使用教程</div>
            </a-card>
            <a-card style="margin-top: 20px;">
                <div class="cardbox">iOS使用教程</div>
            </a-card>
        </div>

        <a-drawer
            title="Basic Drawer"
            :placement="'right'"
            :closable="false"
            :visible="visible"
            @close="visible=false"
        >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
        </a-drawer>
    </div>
</template>
<script>
export default ({
    components: {
       
    },
    data() {
		return {
            visible:false,
        }
    }
})
</script>
<style lang="scss">
.cardbox{
    width: 100%;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}
.wikilist{
    width: 100%;
    padding: 20px;
    display: flex;
    flex-flow: column;
}
.wikibody{
    
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}
</style>