<template>
	<div>
        <a-table :columns="columns" :data-source="nodelist" :pagination="false">
            <template slot="status" slot-scope="status">
                <img :src="status?'/images/onlines.svg':'/images/offline.svg'" width="20px" />
                {{status?'在线':'离线'}}
		    </template>
        </a-table>
    </div>
</template>
<script>
import {GetNodeList} from '../api/request';
export default ({
    components: {
       
    },
    data() {
		return {
            columns:[
                {
                    title: '节点组名称',
                    dataIndex: 'node_name',
                },
                {
                    title: '节点状态',
                    dataIndex: 'status',
                    scopedSlots: { customRender: 'status' },
                },
            ],
            nodelist:[
                
            ]
        }
    },
    mounted(){
        GetNodeList().then(res=>{
            this.nodelist=res.data
        })
    }
})
</script>
<style lang="scss">
</style>