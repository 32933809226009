<template>
	<div>
        <a-spin :spinning="loading">
        <a-table :columns="columns" :data-source="list" :pagination="false">
            <template slot="traffic_type" slot-scope="traffic_type">
                <div class="traffic_type">
                    <img src="/images/jt.svg" width="20px" :class="traffic_type==0?'':'jtround'"/>
                    <span>{{traffic_type==0?'上传':"下载"}}</span>
                </div>
		    </template>
            <template slot="traffic_size" slot-scope="traffic_size">
                {{(traffic_size/1024).toFixed(2)}}KB
		    </template>
            <template slot="traffic_date" slot-scope="traffic_date">
                {{traffic_date|GetDate}}
		    </template>
        </a-table>
    </a-spin>
        <div class="paginationbox">
            <a-pagination v-model:current="current" :total="totalpage" :defaultPageSize="20" />
        </div>
    </div>
</template>
<script>
import {GetTrafficRecords} from '../api/request';
import {formatDate} from '../plugins/formatDate'
export default ({
    components: {
       
    },
    data() {
		return {
            loading:false,
            current:1,
            totalpage:1,
            columns:[
                {
                    title: '流量类型',
                    dataIndex: 'traffic_type',
                    scopedSlots: { customRender: 'traffic_type' },
                },
                {
                    title: '消耗流量',
                    dataIndex: 'traffic_size',
                    scopedSlots: { customRender: 'traffic_size' },
                },
                {
                    title: '记录时间',
                    dataIndex: 'traffic_date',
                    scopedSlots: { customRender: 'traffic_date' },
                },
            ],
            list:[
                
            ]
        }
    },
    watch:{
        // pagination:{
        //     current(oldval,newval){
        //         console.log(oldval,newval)
        //     },
        // }
        current(oldval,newval){
            this.GetData()
        }
    },
    filters: {
        GetDate(value){
            var date = new Date(value * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
            return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
        }
    },
    mounted(){
        this.GetData()
    },
    methods:{
        GetData(){
            this.loading=true
            GetTrafficRecords(this.current).then(res=>{
                this.list=res.data.list
                this.totalpage=res.data.total
                this.loading=false
            })
        }
    }
})
</script>
<style lang="scss">
.paginationbox{
    margin-top: 10px;
    display: flex;
    flex-flow: row-reverse;
}
.jtround{
    transform:rotate(180deg);
    -ms-transform:rotate(180deg); 	/* IE 9 */
    -moz-transform:rotate(180deg); 	/* Firefox */
    -webkit-transform:rotate(180deg); /* Safari 和 Chrome */
    -o-transform:rotate(180deg); 	/* Opera */
}
.traffictype{
    display: flex;
    flex-flow: row;
    align-items: center;
}
</style>